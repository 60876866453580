<template>
  <nav class="navbar" :class="{'navbar-color': !isClient, 'navbar-color-cli': isClient}">
    <!-- <v-spacer></v-spacer> -->   
    <!-- Botão está sendo descontinuado até implementação da funcionalidade de chat no backend
    <v-btn disabled title="Em manutenção" class="ml-4" text dark @click="$root.$emit('live-chat:toggle',true);" style="z-index: 2">
      <v-icon style="height:30px; width:30px;" class="mr-4">$chatIcon</v-icon>Live Chat
    </v-btn> -->
    <div class="d-flex flex-row align-center justify-between w-100">
      <v-spacer></v-spacer>
      <div class="d-flex justify-center flex-grow-1">
        <div id="toolbar">
          <div style="position: relative;">
            <template v-if="highlightField">
              <span class="ping" />
              <span class="ping" />
              <span class="ping" />
            </template>
            <v-autocomplete
              dense
              outlined
              :clearable="!readonly"
              return-object
              hide-details
              item-value="id"
              item-text="fantasia"
              placeholder="Selecione uma empresa"
              :items="clients"
              :value="clientId"
              :readonly="readonly"
              @change="doSelect($event)"
            >
              <template v-slot:item="{ item, on, attrs }">
                <v-list-item
                  v-on="on"
                  v-bind="attrs"
                  class="autocomplete-item"
                >
                  {{ item.fantasia }}
                </v-list-item>
              </template>
            </v-autocomplete>
          </div>
        </div>
      </div>
      <div style="position: absolute; bottom: 0px;">
        <slot></slot>
      </div>
      <div class="d-flex flex-row justify-end align-center">
        <NotificationBell />
        <v-list dense dark style="z-index: 2; background-color:transparent">
          <v-list-item>
            <v-list-item-avatar>
              <img :src="foto" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ user.nome }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-menu offset-y dense left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on" style="z-index: 2">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item style="cursor:pointer" @click="$router.push({ name: 'meu-perfil' })">
              <v-list-item-title class="v-label-input">
                <v-icon left>mdi-account-circle-outline</v-icon>Meu Perfil
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="showAlocarHoras" style="cursor:pointer" @click="$router.push({ name: 'alocar-horas-galapos' })">
              <v-list-item-title class="v-label-input">
                <v-icon left>mdi-table-clock</v-icon>Alocar Horas
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              style="cursor:pointer"
              @click="doLogout()"
            >
              <v-list-item-title class="v-label-input">
                <v-icon left>mdi-power-standby</v-icon>Logout
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </nav>
</template>

<script>
import { UserTypeEnum } from '@/core/enums/user-types';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  components: {
    NotificationBell: () => import("./notification-bell.vue"),
  },
  created: function () {
    this.loadClientList(this.user?.corporacao_id);
  },
  computed: {
    ...mapGetters(['clientId', 'clients', 'foto', 'user']),
    highlightField: function () {
      return ![UserTypeEnum.ADMINISTRADOR, UserTypeEnum.CLIENTE_ADMINISTRADOR, UserTypeEnum.CONSULTOR].includes(this.user?.tipo_usuario) && !this.clientId && this.clients.length > 1;
    },
    isClient: function () {
      return this.getClient().isClient;
    },
    readonly: function () {
      return this.clients.length < 2;
    },
    showAlocarHoras: function () {
      return [UserTypeEnum.ADMINISTRADOR, UserTypeEnum.CONSULTOR].includes(this.user.tipo_usuario);
    },
  },
  methods: {
    ...mapActions(['getClientsList']),
    ...mapMutations(['logout', 'setClient']),
    doLogout: function () {
      this.logout();
      this.$router.push({ name: 'login' });
    },
    doSelect: function (client) {
      if (client?.id !== this.clientId) {
        this.setClient(client?.id || 0);
      }
    },
    loadClientList: function (corporacao_id) {
      return this.getClientsList(corporacao_id).then((clientes) => {
        if (clientes.length === 1) {
          this.doSelect(clientes[0]);
        } else if (this.user.empresa?.empresa_id) {
          const cliente = clientes.find(({ id }) => id === this.user.empresa.empresa_id);
          this.doSelect(cliente);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.navbar-color {
  &:after {
    background: rgba(54, 156, 231, 0.5);
  }
}

.navbar-color-cli {
  &:after {
    background: rgba(54, 225, 231, 0.5);
  }
}

.autocomplete-item {
  color: #1C1C44;
  text-transform: uppercase;

  &.v-list-item--active {
    background-color: #1C1C44;
    border-bottom: thin solid #1C1C44;
    border-top: thin solid #1C1C44;
    color: #fff !important;
  }

  &:not(.v-list-item--active) + & {
    border-top: thin dotted #6d768b;
  }
}



nav {
  z-index: 4;
  color: #6d768b;
  height: 95px;
  background: #fff url("../img/login.jpg") center;
  background-size: 100%;
  position: relative;

  &:after {
    position: absolute;
    
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    content: " ";
  }

  #toolbar {
    z-index: 500;
    margin-top: 18px;
    position: relative;

    .v-autocomplete {
      background: white;
      margin-top: -20px;
      height: 40px;
      width: 400px;
    }
  }
}

.ping {
  background-color: #e4ff00;
  border-radius: 10px;
  position: absolute;
  height: 100%;
  opacity: 0.75;
  width: 100%;
}

@for $i from 1 through 3 {
  .ping:nth-child(#{$i}) {
    animation: ping 1.5s cubic-bezier(0, 0, 0.2, 1) #{($i - 1) * .25}s infinite;
  }
}

@keyframes ping {
  33%,
  100% {
    transform: scale(1.1, 2);
    opacity: 0;
  }
}
</style>
